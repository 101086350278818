import React, {  useRef } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SectionTitle3 from '../SectionTitle3';

import { motion, useInView } from "framer-motion/dist/framer-motion";

import sp1 from '../../images/shop/1.jpg'
import sp2 from '../../images/shop/2.jpg'
/* import sp8 from '../../images/shop/8.jpg' */
import { MdWindow } from 'react-icons/md';
import { GiHeavyRain } from 'react-icons/gi';
import AnimatedServiceItem from './item';

function ProductSectionAnimated() {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    return (
        <div className="wpo-service-area-s3 section-padding" ref={ref} style={{ background: "#16161a" }}>
            <div className="container" style={{ maxWidth: "850px" }}>
                <SectionTitle3 subTitle={'Tutustu tuotteisiin'} MainTitle={'Tuotevalikoima'} />

                <AnimatedServiceItem 
                    title="Peltituotteet"
                    text="Valmistamme ikkunavesipeltejä sinkitystä tai tehdasmaalatusta teräsohutlevystä. Myös kuparista ja alumiinista (0,5mm, 0,6mm, 0,7mm). Vesipeltien kiinnitystapoja on monia erilaisia."
                    image={sp1}
                    link="/tuotteet"
                    icon={<MdWindow size="3em" color="#31aaf1" className="mb-3" style={{
                        marginLeft: "-3px",
                        transform: isInView ? "none" : "translateY(75px)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s"
                    }} />}
                />

                <AnimatedServiceItem 
                    title="Sadevesijärjestelmät"
                    text="Laadukkaat sadevesijärjestelmät sadevesien ja sulamisvesien hallittuun poistamiseen katoilta ja rakennusten läheisyydestä!"
                    image={sp2}
                    link="/tuotteet"
                    icon={<GiHeavyRain size="3em" color="#31aaf1" className="mb-3 mt-3 mt-md-0" style={{
                        marginLeft: "-3px",
                        transform: isInView ? "none" : "translateY(75px)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s"
                    }} />}
                    reversed={true}
                />

                {/* <AnimatedServiceItem 
                    title="Metallituotteet"
                    text="Tekstiä..."
                    image={sp8}
                    link="/tuotteet"
                    icon={<GiMetalPlate size="3em" color="#31aaf1" className="mb-3" style={{
                        marginLeft: "-3px",
                        transform: isInView ? "none" : "translateY(75px)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s"
                    }} />}
                />

                <AnimatedServiceItem 
                    title="Kattotuotteet"
                    text="Tekstiä..."
                    image={sp5}
                    link="/tuotteet"
                    icon={<MdRoofing size="3em" color="#31aaf1" className="mb-3" style={{
                        marginLeft: "-3px",
                        transform: isInView ? "none" : "translateY(75px)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s"
                    }} />}
                    reversed={true}
                /> */}

                <div className="d-flex justify-content-center">
                    <motion.a
                        className="theme-btn-s3"
                        href="/tuotteet"
                        whileHover={{ scale: 1.1, transition: { duration: 0.07 } }}
                        whileTap={{ scale: 0.8 }}
                        transition={{ type: "spring", stiffness: 50, damping: 0 }}>
                        Kaikki tuotteet
                    </motion.a>
                </div>

            </div>
        </div>
    );
}

export default ProductSectionAnimated;

