import React, { Fragment, useEffect, useState } from 'react';
import { core } from '../../features/api.js';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import ShopSingle from '../../components/shopsingle'
import { useParams } from 'react-router-dom'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Prtoducts from '../../api/product'
import Logo from '../../images/logo.svg'
import Navbar2 from '../../components/Navbar2';


const ShopSinglePage = () => {
    const { id } = useParams();
    const [assets, setAssets] = useState([]);
    const [mainImage, setMainImage] = useState('');
    const [currentProduct, setCurrentProduct] = useState([]);
    const [assetLoading, setAssetLoading] = useState(true);

    const ProductDetails = Prtoducts.find(item => item.id === id);

    const { data: Product, isLoading, isFetching } = core.useGetProductQuery(id);

    useEffect(() => {
        setAssets([]);
        setAssetLoading(true);
    }, [id])

    const loadData = () => {
        const assetArray = Product.data[0].attributes.Images?.data;
        const mainImage = Product.data[0].attributes.Images?.data[0]?.attributes;
        setMainImage('http://strapi.marcosraudkett.com:1337' + mainImage?.url);
        setCurrentProduct(Product.data[0].attributes);
        assetArray.map((asset) => {
            const attributes = asset.attributes;
            setAssets(assets => [...assets, {
                id: asset.id,
                title: attributes.alternativeText,
                thumbnail: 'http://strapi.marcosraudkett.com:1337' + attributes.formats.thumbnail.url,
                url: 'http://strapi.marcosraudkett.com:1337' + attributes.url,
                width: parseInt(attributes.width),
                height: parseInt(attributes.height),
            }]);
        });
        setAssetLoading(false);
    }

    if (!isFetching && assetLoading) {
        loadData();
    }

    return (
        <Fragment>
            <Navbar2 Logo={Logo} hclass={'wpo-header-style-2'} />
            <PageTitle pageTitle={currentProduct?.Title} pagesub={
                [
                    {
                        title: "Tuotteet",
                        link: "/tuotteet",
                    },
                    {
                        title: currentProduct?.Title,
                        link: currentProduct?.Url,
                        isActive: true
                    }
                ]
            } />
            <ShopSingle product={currentProduct} assets={assets} image={mainImage} />
            <Footer ftClass={'wpo-site-footer-s2'} />
            <Scrollbar />
        </Fragment>
    )
};
export default ShopSinglePage;

