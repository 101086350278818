import React, { useRef } from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hero1 from '../../images/slider/slide-4.jpg'
import hero2 from '../../images/slider/slide-6.jpg'
import hero3 from '../../images/slider/slide-5.jpg'
import hero4 from '../../images/slider/slide-8.jpg'

import { useInView } from "framer-motion/dist/framer-motion";

function Hero4() {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    var settings = {
        dots: false,
        arrows: true,
        speed: 1200,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4500,
        fade: true
    };

    return (
        <section className="wpo-hero-slider wpo-hero-style-3" ref={ref}>
            <div className="wpo-line-animated">
                <span style={{ background: "linear-gradient(#ffffff3b, #202026)" }}></span>
                <span style={{ background: "linear-gradient(#ffffff3b 60%, #202026)" }}></span>
                <span style={{ background: "linear-gradient(#ffffff3b 80%, #202026)" }}></span>
                <span style={{ background: "linear-gradient(#ffffff3b 60%, #202026)" }}></span>
                <span style={{ background: "linear-gradient(#ffffff3b, #202026)" }}></span>
            </div>
            <div className="hero-container">
                <div className="hero-wrapper">
                    <Slider {...settings}>
                        <div className="hero-slide">
                            <div className="slide-inner" style={{ backgroundImage: `linear-gradient(to bottom, transparent, #202026), url(${hero1})` }}>
                                <div className="container-fluid">
                                    <div className="slide-content">
                                        <div className="slide-title">
                                            <h2 style={{
                                                transform: isInView ? "none" : "translateY(75px)",
                                                opacity: isInView ? 1 : 0,
                                                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s"
                                            }}>Peltituotteet</h2>
                                        </div>
                                        <div className="clearfix"></div>
                                        <div className="slide-btns" style={{
                                            transform: isInView ? "none" : "translateY(75px)",
                                            opacity: isInView ? 1 : 0,
                                            transition: "all 1s cubic-bezier(0.17, 0.55, 0.55, 1) 0.8s"
                                        }}>
                                            <Link to="/tuotteet" className="theme-btn-s3">Lue lisää</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hero-slide">
                            <div className="slide-inner" style={{ backgroundImage: `linear-gradient(to bottom, transparent, #202026), url(${hero2})` }}>
                                <div className="container-fluid">
                                    <div className="slide-content">
                                        <div className="slide-title">
                                            <h2>Sadevesi- <br />järjestelmät</h2>
                                        </div>
                                        <div className="clearfix"></div>
                                        <div className="slide-btns">
                                            <Link to="/tuotteet" className="theme-btn-s3">Lue lisää</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hero-slide">
                            <div className="slide-inner" style={{ backgroundImage: `linear-gradient(to bottom, transparent, #202026), url(${hero3})` }}>
                                <div className="container-fluid">
                                    <div className="slide-content">
                                        <div className="slide-title">
                                            <h2>Metallituotteet</h2>
                                        </div>
                                        <div className="clearfix"></div>
                                        <div className="slide-btns">
                                            <Link to="/tuotteet" className="theme-btn-s3">Lue lisää</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hero-slide">
                            <div className="slide-inner" style={{ backgroundImage: `linear-gradient(to bottom, transparent, #202026), url(${hero4})` }}>
                                <div className="container-fluid">
                                    <div className="slide-content">
                                        <div className="slide-title">
                                            <h2>Kattotyöt</h2>
                                        </div>
                                        <div className="clearfix"></div>
                                        <div className="slide-btns">
                                            <Link to="/palvelut/kattopeltityöt" className="theme-btn-s3">Lue lisää</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default Hero4;