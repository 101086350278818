import React from 'react';
import Services from '../../api/service'


function truncateString(str, num) {
    if (str.length <= num) {
        return str
    }
    return str.slice(0, num) + '...'
}

const RelatedService = (props) => {


    return (
        <div className="wpo-service-single-item">
            <div className="wpo-service-single-title">
                <h3>Muita palveluita</h3>
            </div>
            <div className="wpo-service-area">
                <div className="row align-items-center">
                    {props?.services?.data.slice(0, 4).map((service, sitem) => {
                        if (props?.Title?.toLowerCase() != service?.id) {
                            return (
                                <div className="col-lg-4 col-md-6 col-12" key={sitem}>
                                    <div className="wpo-service-item">
                                        <i className={service?.attributes?.icon}></i>
                                        <h2>{service?.attributes?.Title}</h2>
                                        <p dangerouslySetInnerHTML={{ __html: truncateString(service?.attributes?.Description, 90) }} />
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        </div>
    )
};
export default RelatedService;

