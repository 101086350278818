// Import the RTK Query methods from the React-specific entry point
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


const baseQuery = fetchBaseQuery({
    baseUrl: 'http://strapi.marcosraudkett.com:1337/api/'
});
const baseQueryWithInterceptors = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    if (result.error) {
        console.log(result);
    }

    return result;
};

export const coreSlice = createApi({
    reducerPath: 'core',
    baseQuery: baseQueryWithInterceptors,
    tagTypes: ['Services', 'Products'],
    endpoints: (builder) => ({
        getServices: builder.query({
            query: ({ search }) => {
                return {
                    url: '/services',
                    params: {
                        'fields[0]': 'title',
                        'fields[1]': 'description',
                        'fields[2]': 'url',
                        'populate[0]': 'Image',
                        'filters[Title][$containsi]': search
                    }
                };
            },
            providesTags: ['Services']
        }),
        getService: builder.query({
            query: (service_slug) => {
                return {
                    url: `/services`,
                    params: {
                        'filters[Url][$eq]': service_slug,
                        'populate[0]': 'Image',
                        'populate[1]': 'Images',
                    }
                }
            },
        }),
        getProducts: builder.query({
            query: ({ search, category = "" }) => {
                return {
                    url: '/products',
                    params: {
                        'fields[0]': 'title',
                        'fields[1]': 'description',
                        'fields[2]': 'url',
                        'populate': 'Images,categories',
                        'filters[Title][$containsi]': search,
                        'filters[categories][Name][$containsi]': category,
                    }
                };
            },
            providesTags: ['Products']
        }),
        getProduct: builder.query({
            query: (product_slug) => {
                return {
                    url: `/products`,
                    params: {
                        'filters[Url][$eq]': product_slug,
                        'populate[1]': 'Images',
                    }
                }
            }
        })
    })
});

export const {
    useGetServicesQuery,
    useGetServiceQuery,
    useGetProductsQuery,
    useGetProductQuery,
} = coreSlice;