import React, { useState } from 'react'
import SectionTitle from '../SectionTitle'
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

const Jobs = [
    {
        id: 1,
        modal: "rakennuspeltiseppa",
        title: "Rakennuspeltiseppä",
        desc: "test",
        location: "Helsinki",
        duration: "Täysipäivänen",
    },
    /* {
        id: 2,
        modal: "avoin-hakemus",
        title: "Avoin hakemus",
        desc: "test",
        location: "Helsinki",
        duration: "Täysipäivänen",
    }, */
];

const TeamSection = (props) => {
    const [selectedId, setSelectedId] = useState(null)
    const [selectedJob, setSelectedJob] = useState([])

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }


    return (

        <section className="wpo-team-section section-padding">
            <div className="container" style={{ maxWidth: "650px" }}>
                <SectionTitle subTitle={'AVOIMET TYÖPAIKAT'} MainTitle={'Meille töihin? 💼'} />
                <div className="wpo-team-wrap">
                    <div className="row">
                        {Jobs.slice(0, 4).map(job=> (
                            <motion.div key={job.id} className="col-lg-12 col-12" style={{ cursor: "pointer" }} layoutId={job.id} onClick={() => {
                                setSelectedId(job.id);
                                setSelectedJob(job);
                            }}>
                                <motion.div className="card card-job text-white">
                                    <motion.div className="card-body">
                                        <motion.h2 className="card-title text-white">{job.title}</motion.h2>
                                        <motion.p className="text-white">
                                            <motion.span className="pe-2">{job.location}</motion.span>
                                            <motion.span className="pe-2">{job.duration}</motion.span>
                                        </motion.p>
                                    </motion.div>
                                </motion.div>
                            </motion.div>
                        ))}
                        <AnimatePresence>
                            {selectedId && (
                                <motion.div layoutId={selectedId}>
                                    <motion.h5>{selectedJob.subtitle}</motion.h5>
                                    <motion.h2>{selectedJob.title}</motion.h2>
                                    <motion.button onClick={() => setSelectedId(null)} />
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default TeamSection;