import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2'
import PageTitle from '../../components/pagetitle'
import About from '../../components/about'
import ServiceSection from '../../components/Services';
import FunFact from '../../components/FunFact'
import TeamSection from '../../components/TeamSection';
import Testimonial from '../../components/Testimonial';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.png'
import abimg from '../../images/about.jpg'

import { SuperSEO } from "react-super-seo";
import pageBanner from '../../images/banner-about.jpg';


const AboutPage = (props) => {
    return (
        <Fragment>
            <SuperSEO
                title="Meistä | Peltisepänliike Entisöintipläkkärit Oy"
                description=""
                lang="fi"
            />

            <Navbar2 Logo={Logo} hclass={'wpo-header-style-2'} />
            <PageTitle pageTitle={'Yritys'} pagesub={'Meistä'} banner={pageBanner} />
            <About abimg={abimg} />
            <ServiceSection />
            <FunFact fnClass={'wpo-fun-fact-section-s2'} />
            <TeamSection />
            <Footer ftClass={'wpo-site-footer-s2'} />
            <Scrollbar />
        </Fragment>
    )
};
export default AboutPage;
