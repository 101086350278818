import React, { Fragment, useState, useEffect } from 'react';
import { core } from '../../features/api.js';
import PageTitle from '../../components/pagetitle'
import Scrollbar from '../../components/scrollbar'
import { Link, useParams } from 'react-router-dom'
import Services from '../../api/service'
import RelatedService from './related';
import ServiceSidebar from './sidebar';
import Logo from '../../images/logo.svg'
import Footer from '../../components/footer';
import Navbar2 from '../../components/Navbar2';
import Gallery from '../../components/Gallery';
import { SuperSEO } from "react-super-seo";
import pageTitle from '../../images/page-title.jpg';

const ServiceSinglePage = (props) => {
    const { id } = useParams();
    const [assets, setAssets] = useState([]);
    const [mainImage, setMainImage] = useState(pageTitle);
    const [currentService, setCurrentService] = useState([]);
    const [assetLoading, setAssetLoading] = useState(true);

    const { data: Services } = core.useGetServiceQuery();
    const { data: Service, isLoading, isFetching } = core.useGetServiceQuery(id);

    useEffect(() => {
        setMainImage(pageTitle);
        setAssets([]);
        setAssetLoading(true);
    }, [id])

    const loadData = () => {
        const assetArray = Service.data[0]?.attributes?.Images?.data;
        const mainImage = Service.data[0].attributes.Image.data.attributes;
        setMainImage('http://strapi.marcosraudkett.com:1337' + mainImage.url);
        setCurrentService(Service.data[0].attributes);
        if (assetArray) {
            assetArray.map((asset) => {
                const attributes = asset.attributes;
                setAssets(assets => [...assets, {
                    id: asset.id,
                    title: attributes.alternativeText,
                    thumbnail: 'http://strapi.marcosraudkett.com:1337' + attributes.formats.thumbnail.url,
                    url: 'http://strapi.marcosraudkett.com:1337' + attributes.url,
                    width: parseInt(attributes.width),
                    height: parseInt(attributes.height),
                }]);
            });
        }
        setAssetLoading(false);
    }

    if (!isFetching && assetLoading) {
        loadData();
    }

    return (
        <Fragment>
            <SuperSEO
                title={`${currentService?.Title} | Peltisepänliike Entisöintipläkkärit Oy`}
                description=""
                lang="fi"
            />

            <Navbar2 Logo={Logo} hclass={'wpo-header-style-2'} />
            <PageTitle pageTitle={currentService?.Title} pagesub={
                [
                    {
                        title: "Palvelut",
                        link: "/palvelut",
                    },
                    {
                        title: currentService?.Title,
                        link: currentService?.Url,
                        isActive: true
                    }
                ]
            } banner={mainImage}
            />
            <div className="wpo-service-single-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-12">
                            <div className="wpo-service-single-wrap">
                                <div className="wpo-service-single-item">
                                    <div className="wpo-service-single-main-img">
                                        <img src={mainImage} alt="" />
                                    </div>
                                    <div className="wpo-service-single-title">
                                        <h3>{currentService?.Title}</h3>
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: currentService?.Description }} />
                                </div>
                                <Link to="/yhteystiedot" className="theme-btn-s3 mb-5">Pyydä tarjousta</Link>
                                <div className="wpo-service-single-item">
                                    {assets.length !== 0 ? (
                                        <div className="wpo-service-single-title">
                                            <h3>Kuvia</h3>
                                        </div>
                                    ) : null}
                                    <div className="row">
                                        {!isLoading && !assetLoading && assets.length >= 0 ? (
                                            <Gallery
                                                col="12"
                                                col_md="6"
                                                col_sm="6"
                                                col_lg="4"
                                                galleryID="my-test-gallery"
                                                images={assets}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                                <RelatedService services={Services} currentId={id} />
                                {/* <Discuss /> */}
                            </div>
                        </div>
                        <ServiceSidebar services={Services} />
                    </div>
                </div>
            </div>
            <Footer ftClass={'wpo-site-footer-s2'} />
            <Scrollbar />
        </Fragment>
    )
};
export default ServiceSinglePage;
