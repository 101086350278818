import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import VideoModal from '../../components/ModalVideo'
import abimg from '../../images/about5.jpg'
import abimg2 from '../../images/about-shape3.png'

import { motion, useInView } from "framer-motion/dist/framer-motion";


const About4 = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    return (

        <div className="wpo-about-area-s4 section-padding" ref={ref}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="wpo-about-img">
                            <img src={abimg} alt="" style={{
                                transform: isInView ? "none" : "translateY(75px)",
                                opacity: isInView ? 1 : 0,
                                transition: "all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.4s"
                            }} />
                            <div className="wpo-about-img-text" style={{
                                transform: isInView ? "none" : "translateY(75px)",
                                opacity: isInView ? 1 : 0,
                                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 1.5s"
                            }}>
                                <h2>15+</h2>
                                <p>Vuotta kokemusta</p>
                                <div className="about-shape">
                                    <img src={abimg2} alt="" />
                                </div>
                            </div>
                            <div className="left-shape" style={{
                                transform: isInView ? "none" : "translateY(75px)",
                                opacity: isInView ? 1 : 0,
                                transition: "all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s"
                            }}>
                                <div className="square-shape"></div>
                                <div className="shape-top">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <div className="shape-left" >
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 colsm-12" style={{
                        transform: isInView ? "none" : "translateY(75px)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s"
                    }}>
                        <div className="wpo-about-text">
                            <div className="wpo-about-title">
                                <span>Meistä</span>
                                <h2>Laatu ennen määrää 🌟</h2>
                            </div>
                            <h5>Yli 15 vuoden kokemuksella tunnemme, mitä talojen eri vuosikymmeninä vedetyt pellit pitävät sisällään.</h5>
                            <p>
                                Me Entisöintipläkkäreissä pyrimme määrittelemään urakan aluksi kaiken tarvittavan ja antamaan tällä perusteella realistisen hinta-arvion kokonaisuudesta. Kokemus auttaa tässä..</p>
                            <div className="btns">
                                <Link to="/about" onClick={ClickHandler} className="theme-btn">Lue lisää</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="invisible-title1">
                <h2>Meistä</h2>
            </div>
        </div>
    )
}

export default About4;